<template>
  <div>
    <div class="content">
      <div class="all">
        <div class="SearchBar">
          <el-form
              ref="search"
              :inline="true"
              size="small"
          >
            <el-form-item label="物料名称:" class="searchItem">
              <el-input v-model="pageParams.materialName" size="mini" placeholder="请输入物料名称" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="" class="searchItem">
              <el-button type="primary" @click="search" size="mini" >查询</el-button>
              <el-button type="warning" @click="restSearch" size="mini" >重置</el-button>
            </el-form-item>
          </el-form>
          <el-button type="primary" @click="addMore" size="mini" v-if="$anthButtons.getButtonAuth('wllbtj')">添加</el-button>
          <el-table
              ref="tableData"
              :data="tableData"
              style="margin-top: 10px"
              border
              :header-cell-style="{
							color: '#333',
							fontFamily: 'MicrosoftYaHeiUI',
							fontSize: '14px',
							fontWeight: 900,
							textAlign: 'left',
							background: '#f8f8f9',
						}"
              height="calc(100vh - 330px)"
              :stripe="true"
          >
            <el-table-column
                type="index"
                label="序号"
                width="50"
            >
            </el-table-column>
            <el-table-column
                prop="materialName"
                label="物料名称"
            >
            </el-table-column>
            <el-table-column
                prop="materialCostPrice"
                label="成本价"
            >
            </el-table-column>
            <el-table-column
                prop="materialSalePrice"
                label="指导价"
            >
            </el-table-column>
            <el-table-column
                prop="materialSaleMax"
                label="售价上限"
            >
            </el-table-column>
            <el-table-column
                prop="materialSaleMin"
                label="售价下限"
            >
            </el-table-column>
            <el-table-column
                prop="sortNum"
                label="排序"
            >
            <template slot-scope="{ row }">
              <el-input v-model.number="row.sortNum"
                        type="number"
                        placeholder="请输入排序"
                        @input="row.sortNum = row.sortNum>9999  ? 9999 : (( row.sortNum< 0) ? parseInt(Math.abs(row.sortNum)): parseInt(row.sortNum))"
                        @blur="sortNumber(row)"></el-input>
            </template>
            </el-table-column>
            <el-table-column
                prop="updateBy"
                label="操作人"
            >
            </el-table-column>
            <el-table-column
                prop="updateTime"
                label="操作时间"
            >
            </el-table-column>
            <el-table-column
                prop="name"
                label="操作"
            >
              <template slot-scope="{row}">
                <el-button type="primary" size="mini" style="margin-right: 10px" @click="modify(row)" v-if="$anthButtons.getButtonAuth('wllbbj')">编辑</el-button>
                <span v-if="$anthButtons.getButtonAuth('wllbqty')">
                  <el-button v-if="row.materialStatus==1" type="danger" size="mini" style="margin-right: 10px" @click="handle(row)" >停用</el-button>
                  <el-button v-else type="success" size="mini" style="margin-right: 10px" @click="handle(row)">启用</el-button>
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="total">
            <el-pagination
                background
                layout="total, prev, pager, next,sizes, jumper"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="pageParams.current"
                :page-sizes="[10, 30, 50, 100]"
                :page-size="pageParams.size"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="uodataStatus?'新增物料':'编辑物料'"
        :visible.sync="dialogVisible"
        width="30%"
        :close-on-click-modal="false"
        @close="closeDiaog('pgform')"
    >
      <el-form ref="pgform" :model="pgform" label-width="80px" :rules="rules">
        <el-form-item label="物料名称" prop="materialName">
          <el-input type="text" placeholder="请输入" v-model="pgform.materialName"></el-input>
        </el-form-item>
        <el-form-item label="成本价" prop="materialCostPrice">
          <el-input type="number" placeholder="请输入" v-model="pgform.materialCostPrice" >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="指导价" prop="materialSalePrice">
          <el-input type="number" placeholder="请输入" v-model="pgform.materialSalePrice" >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="售价上限" prop="materialSaleMax">
          <el-input type="number" placeholder="请输入" v-model="pgform.materialSaleMax" >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="售价下限" prop="materialSaleMin">
          <el-input type="number" placeholder="请输入" v-model="pgform.materialSaleMin" >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button v-if="uodataStatus" type="primary" @click="submit('pgform')">确 定</el-button>
        <el-button v-else type="primary" @click="modifysubmit('pgform')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {saveDisMaterial, selectDisMaterialList,updateDisMaterial,toggleDisMaterial} from "../../RequestPort/kfOrder";
export default {
  name: "statistics",
  data(){
    const validateMoney = (rule,value,callback) =>{
      if(!value){
        callback(new Error('价格不能为空'))
      }else if(value.indexOf(".") != -1 && value.split('.').length > 2){
        callback(new Error('请输入正确格式的金额')) //防止输入多个小数点
      }else if(value.indexOf(".") != -1 && value.split('.')[1].length > 2){
        callback(new Error('请输入正确的小数位数')) //小数点后两位
      }else{
        callback();
      }
    }
    const maxRule = (rule, value, callback) => {
      if (this.pgform.materialSalePrice) {
        if (Number(value) > Number(this.pgform.materialSalePrice)) {
          callback(new Error('售价下限不得大于指导价'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    const minRule = (rule, value, callback) => {
      if (this.pgform.materialSalePrice) {
        if (Number(value) < Number(this.pgform.materialSalePrice)) {
          callback(new Error('售价上限不得小于指导价'))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
    return{
      uodataStatus:true,
      dialogVisible:false,
      pageParams:{
        materialName:'',
        size: 100,
        current:1
      },
      total:null,
      time:[],
      tableData:[],
      pgform:{
        materialCostPrice:null,
        materialName: "",
        materialSaleMax: null,
        materialSaleMin: null,
        materialSalePrice: null
      },
      rules:{
        materialName: [{ required: true, message: "请输入", trigger: "change" }],
        materialCostPrice: [{ required: true, message: "请输入", trigger: "change" },
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '小数点前限制6位，小数点后限制2位'
          }
        ],
        materialSalePrice: [{ required: true, message: "请输入", trigger: "change" },
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '小数点前限制6位，小数点后限制2位'
          }
          ],
        materialSaleMax: [
            { required: true, message: "请输入", trigger: "change" },
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '小数点前限制6位，小数点后限制2位'
          },
            { validator: minRule, trigger: 'change' },
         ],
        materialSaleMin: [{ required: true, message: "请输入", trigger: "change" },
          {
            pattern: /(^[1-9]\d{0,5}?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: '小数点前限制6位，小数点后限制2位'
          },
            { validator: maxRule, trigger: 'change' },
          ],
      }
    }
  },
  mounted() {
    this.loadList(this.pageParams);
  },
  methods:{
    closeDiaog(formName){
     this.pgform = {
        materialCostPrice:null,
            materialName: "",
            materialSaleMax: null,
            materialSaleMin: null,
            materialSalePrice: null
      }
      this.$refs[formName].resetFields(); // 重置表单移除校验
    },
    handle(row){
      let str = row.materialStatus==1?'是否停用？':'是否启用？'
      this.$confirm(str, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal:false,
        type: 'warning'
      }).then(() => {
        let obj = {
          id:row.id,
          materialStatus:row.materialStatus = row.materialStatus==1?2:1
        }
        toggleDisMaterial(obj).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogVisible = false
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    sortNumber(row){
      updateDisMaterial(row).then(res=>{
        if(res.code===200){
          this.$message.success(res.data)
          this.dialogVisible = false
          this.loadList(this.pageParams);
        }else {
          this.$message.error(res.msg)
        }
      })
    },
    submit(pgform){
      this.$refs[pgform].validate((valid) => {
        if(!valid) return
        saveDisMaterial(this.pgform).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogVisible = false
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    modifysubmit(pgform){
      this.$refs[pgform].validate((valid) => {
        if(!valid) return
        updateDisMaterial(this.pgform).then(res=>{
          if(res.code===200){
            this.$message.success(res.data)
            this.dialogVisible = false
            this.loadList(this.pageParams);
          }else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    modify(row){
      this.uodataStatus = false
      let obj = JSON.parse(JSON.stringify(row))
      this.pgform = obj
      this.dialogVisible = true
    },
    search() {
      this.pageParams.current = 1;
      this.loadList(this.pageParams);
    },
    restSearch() {
      this.pageParams = {
        materialName:'',
        current: 1,
        size: 100
      };
      this.loadList(this.pageParams);
    },
    addMore(){
      this.uodataStatus = true
      this.dialogVisible = true
    },
    loadList(obj){
      selectDisMaterialList(obj).then(res=>{
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    handleCurrentChange(current) {
      this.pageParams.current = current;
      this.loadList(this.pageParams);
    },
    handleSizeChange(val) {
      this.pageParams.size = val;
      this.handleCurrentChange(1);
    },
  }
}
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
.tableTool{
  height: 25px;
}
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  border-radius: 5px;
  margin: 10px;
  padding-top: 10px;
  padding-left: 20px;
  background-color: #ffffff;
}
.all {
  background-color:#f3f4f8;height:auto
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  overflow:auto;
  height: calc(100vh - 120px);
}
.search{
  width: 190px;
}
.total{
  margin-left: 15px;
  margin-top: 10px;
}
.table{
  overflow:auto;
  height: 520px;
}
</style>